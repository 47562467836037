import CONFIG from 'config';

export const processBoldMarkdown = (text) => {
  return text?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
};

export const processLinkMarkdown = (text) => {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  return text.replace(linkRegex, (match, label, href) => {
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${label}</a>`;
  });
};

export const processCustomMarkdown = (markdownKey, text, toolData, tool) => {
  if(!markdownKey) {
    return text;
  }
  const key = markdownKey?.match(/\d+-[^-|\]]+/ig)?.[0]?.replace(/\[|\]/ig, '') || '';
  const citationNum = markdownKey?.match(/\d+/ig)?.[0] || 0;
  let replacementContent = '';
  let output = '';
  
  const ref = toolData?.[key];
  switch (tool) {
    case CONFIG.AGENTS_TOOL_GROUPS.KN.TOOL:
      if (ref?.kp_cms_id && ref?.page) {
        replacementContent = `<sup><a href="${CONFIG.UI_URL.PREVIEW_LINK(ref?.kp_cms_id, ref?.page)}" target="_blank">${citationNum}</a></sup> `;
      }
        
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.LSEG.TOOL:
      const formattedKey = `${key}`;
      const refKey = toolData?.[formattedKey];
      if (refKey?.s3_path) {
        replacementContent = `<a style="color: #177B57;" href="${refKey?.s3_path}" target="_blank">${citationNum}</a>`;
      }
        
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXPERTS.TOOL:
      replacementContent = markdownKey?.match(/:\s*[^\]]*/)?.[0]?.replace(':', '') || '';
      const replacementKey = markdownKey?.match(/s-[^:]+/)?.[0]?.replace('s-', '') || '';
      let replacementLink = '';
  
      if(replacementContent && ref) {
        const dateFilter = 'Past Two Years';

        switch (replacementKey) {
          case 'Name':
            replacementLink = CONFIG.NAVIGATOR_LINKS.PROFILE(ref?.hrid);
            break;
          case 'Cases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '');
            break;
          case 'recentMaterials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'recentCases':
            replacementLink = CONFIG.NAVIGATOR_LINKS.CASES(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '', dateFilter);
            break;
          case 'Materials':
            replacementLink = CONFIG.NAVIGATOR_LINKS.MATERIALS(ref?.hrid, ref?.inferredQuery?.replace(/"|'/g, '') || '');
        }
  
        replacementContent = `<a class="expertsLinks" target="_blank" href="${replacementLink}">${replacementContent}</a>`;
      }
  
      output = text.replaceAll(markdownKey, replacementContent);
      break;
    case CONFIG.AGENTS_TOOL_GROUPS.EXA_AI.TOOL:
      if (ref?.url) {
        const domain = (new URL(ref?.url))?.hostname;
        replacementContent = domain ? `<sup><a href="${ref?.url}" target="_blank">${domain}</a></sup>` : '';
      }
      output = text.replaceAll(markdownKey, replacementContent);
      break;
  }
  
  return [output, key];
};